import React from 'react';
import Slider from 'react-slick';

function CareerSlider() {
	const settings = {
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 2,
		slidesToScroll: 1,
		arrows: true,
		responsive: [
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1
				}
			}
		]
	};

	return (
		<div className="c-careers-slider">
			<Slider {...settings}>
				<div className="c-careers-slider-slide">
					<img
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/alonso.jpg"
						className="o-fluid-img"
						alt=""
						loading="lazy"
					/>
					<div className="c-careers-slider-slide__text">
						<p className="c-careers-slider-slide__title">Alonso De Lope Torres</p>
						<p className="c-careers-slider-slide__sub-title">Marketing Manager, Miami</p>
						<p className="c-careers-slider-slide__copy">
							“Working at hear.com has been a game-changer for me. The team here is incredibly supportive and always
							encourage me to bring my best ideas to the table. It's truly an amazing place to work”
						</p>
					</div>
				</div>
				<div className="c-careers-slider-slide">
					<img
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/careers/valerie.jpg"
						className="o-fluid-img"
						alt=""
						loading="lazy"
					/>
					<div className="c-careers-slider-slide__text">
						<p className="c-careers-slider-slide__title">Valerie De Armas,</p>
						<p className="c-careers-slider-slide__sub-title">Recruiter, Coral Gables</p>
						<p className="c-careers-slider-slide__copy">
							“I really enjoy the company culture. I am lucky enough to work with a team of driven individuals who
							consistently push each other to grow and learn, but also make time to have fun and enjoy each others
							presence”
						</p>
					</div>
				</div>
			</Slider>
		</div>
	);
}

export default CareerSlider;
